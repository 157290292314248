
import { defineComponent } from "vue";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiEndpoint";
import { VueCookieNext } from "vue-cookie-next";
import moment from "moment";

import axios from 'axios';
import { ElNotification } from 'element-plus';


export default defineComponent({
  mixins: [apiEndpoint],
  name: "new-job-tracking",
  components: {
    Datatable,
  },
  data() {
    return {
      batch: {
        entity_id: "",
        tranche_id: "",
        institute_id: "",
        course_id: "",
        batch_id: "",
      },
      tableData: [],
      tableHeader: [
        {
          name: "Image",
          key: "image",
          sortable: true,
          width: "5px",
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Father Name",
          key: "father_name",
          sortable: true,
        },
        {
          name: "Mother Name",
          key: "mother_name",
          sortable: true,
        },
        {
          name: "NID",
          key: "nid",
          sortable: true,
        },

        {
          name: "Phone",
          key: "phone",
          sortable: true,
        },
        {
          name: "DoB",
          key: "dob",
          sortable: true,
        },
      ],
      EmploymenttableHeader: [
        {
          name: "SN",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Organization",
          key: "name",
          sortable: true,
        },
        {
          name: "Designation",
          key: "designation",
          sortable: true,
        },
        {
          name: "Salary",
          key: "salary",
          sortable: true,
        },
        {
          name: "Joining Date",
          key: "join_date",
          sortable: true,
        },

        {
          name: "Release Date",
          key: "release_date",
          sortable: true,
        },
        {
          name: "Durations",
          key: "duration",
          sortable: true,
        },
      ],
      moment: "" as any,
      tranches_info: [],
      entityInfos: [],
      instituteList: [],
      details: [],
      courseList: [],
      batchList: [],
      loading: false,
      showJobData: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    this.moment = moment;
    await this.getTranches();
    await this.getEntityInfos();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_id', this.batch.entity_id);
      formData.set('training_institute_id', this.batch.institute_id);
      formData.set('course_info_id', this.batch.course_id);
      formData.set('batch_info_id', this.batch.batch_id);
      axios
        .post(`${this.VUE_APP_API_URL}/api/employment/jobtrackingprint`, formData)
        .then(response => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },

    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_id', this.batch.entity_id);
      formData.set('training_institute_id', this.batch.institute_id);
      formData.set('course_info_id', this.batch.course_id);
      formData.set('batch_info_id', this.batch.batch_id);
      await ApiService.post('employment/jobtrackingpdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_id', this.batch.entity_id);
      formData.set('training_institute_id', this.batch.institute_id);
      formData.set('course_info_id', this.batch.course_id);
      formData.set('batch_info_id', this.batch.batch_id);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/employment/jobtrackingexport`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'jobtracking-export-report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async getTranches() {
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.loading = false;
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getEntityInfos() {
      this.loading = true;
      let entity_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get(this.VUE_APP_GET_ENTITY_LIST_API + "?entity_id=" + entity_id)
        .then((response) => {
          this.loading = false;
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async batchInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_BATCH_LIST_API +
        "?entity_id=" +
        this.batch.entity_id +
        "&course_info_id=" +
        this.batch.course_id +
        "&institute_info_id=" +
        this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          this.batchList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_info_id = VueCookieNext.getCookie("_institute_info_id");
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API + "?entity_id=" + this.batch.entity_id + "&institute_info_id=" + institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async courseInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
        "?entity_id=" +
        this.batch.entity_id +
        "&tranche=" +
        this.batch.tranche_id +
        "&institute_info_id=" +
        this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getDetails() {
      if (this.batch.batch_id) {
        this.loading = true;
        ApiService.get(
          "employment/jobtracking?tranche_id=" +
          this.batch.tranche_id +
          "&entity_id=" +
          this.batch.entity_id +
          "&training_institute_id=" +
          this.batch.institute_id +
          "&course_info_id=" +
          this.batch.course_id +
          "&batch_info_id=" +
          this.batch.batch_id
        )
          .then((response) => {
            this.details = response.data.data;
            this.loading = false;
            this.showJobData = true;
          })
          .catch((response) => {
            console.log(response);
            this.loading = false;
          });
      } else {
        this.loading = false;
        Swal.fire({
          title: "Warning!",
          html: "Please select all required fields.",
          icon: "warning",
        });
      }
    },
  },
  setup() { },
});
